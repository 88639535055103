import { tns } from 'tiny-slider/src/tiny-slider'

// /* ---------- CATEGORY: SLIDER ----------- */
document.querySelectorAll('.content6-slider').forEach((slider) => {
    tns({
        container: slider,
        items: 4,
        slideBy: 2,
        controls: false,
        nav: false,
        autoplay: true,
        autoplayButtonOutput: false,
        speed: 1000,
        loop: true,
        responsive: {
            150: {
                gutter: 0,
                items: 1,
            },
            320: {
                gutter: 5,
                items: 2,
            },
            768: {
                items: 6,
            },
        },
    })
})

document.querySelectorAll('.supported-slider').forEach((slider) => {
    tns({
        container: slider,
        items: 6,
        slideBy: 1,
        controls: false,
        nav: false,
        fixedWidth: 176,
        autoplay: true,
        autoplayButtonOutput: false,
        speed: 1000,
        viewportMax: 1000,
        loop: true,
        responsive: {
            150: {
                gutter: 0,
                items: 1,
            },
            320: {
                gutter: 5,
                items: 2,
            },
            640: {
                gutter: 10,
                items: 2,
            },
            700: {
                gutter: 15,
                items: 4,
            },
            900: {
                items: 6,
            },
        },
    })
})

document.querySelectorAll('.vertical-center-4').forEach((slider) => {
    tns({
        container: slider,
        items: 4,
        controls: false,
        nav: false,
        autoWidth: true,
        autoplay: true,
        autoplayButtonOutput: false,
        speed: 2000,
        loop: true,
        responsive: {
            150: {
                gutter: 0,
                items: 1,
            },
            320: {
                gutter: 5,
                items: 2,
            },
            640: {
                gutter: 5,
                items: 2,
            },
            700: {
                gutter: 10,
                items: 3,
            },
            900: {
                gutter: 10,
                items: 4,
            },
        },
    })
})
